div.sidemenu {
    background-color: #434444;
}

div.sidemenu-separator{
    background-color: #555555;
}

div.sidemenu-item {
    color: #fff;
}

div.sidemenu-item.active {
    color: #000;
    background-color: #aab239;
}

div.sidemenu-item:hover {
    font-weight: bold;
    color: #000;
    background-color: #aab239;
}