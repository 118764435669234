body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spinner-loading {
  position: relative;
  cursor: default !important;
}
.spinner-loading:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #555;
}

.spinner-loading:after {
  content: '';
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  animation: spinner-loading linear infinite forwards .5s;
}

@keyframes spinner-loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}