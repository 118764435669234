.btn_delete_deployment {
    width: 20%;
    height: 40px;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: Arial, Helvetica, sans-serif;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    opacity: 0;
    -webkit-transition: opacity 400ms ease-in;
    -moz-transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
    pointer-events: none;
    color: #fff;
}

.modal:target {
    opacity: 1;
    pointer-events: auto;
}

.modal > div {
    width: 500px;
    position: relative;
    margin: 10% auto;
    padding: 15px 20px;
    background: #000;
}

.fechar {
    position: absolute;
    width: 30px;
    right: -15px;
    top: -20px;
    text-align: center;
    line-height: 30px;
    margin-top: 5px;
    background: #ff4545;
    border-radius: 50%;
    font-size: 16px;
    color: #8d0000;
}

#centerdiv {
    margin: auto;
    margin-top: 10%;
}

.aforbtn {
    display: block;
    width: 160px;
    height: 40px;
    line-height: 40px;
    padding: 10px 5px;
    margin: 20px;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    font-family: Arial;
}

.kk {
    display: block;
    width: 160px;
    height: 40px;
    line-height: 40px;
    padding: 10px 5px;
    margin: 20px;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    font-family: Arial;
}
